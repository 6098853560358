@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=IBM+Plex+Sans:wght@300&family=Nunito:wght@500&display=swap');

body {
  background: #f0ece2;
}

.page-container {
  display: flex;
}

.info-container {
  text-align: left;
  font-size: 16.5px;
  margin-left: 44vw;
  margin-top: 2vw;
  margin-right: 25vw;
  overflow-wrap: normal;
  overflow: hidden;
  font-family: 'DM Sans', sans-serif;
  color: rgb(55,55,65);
}

.name {
  white-space: nowrap;
  font-size: 37px;
  color: rgb(55,55,64);
  text-decoration: none;
  font-family: 'DM Sans', sans-serif;
}

@media (max-width: 1100px) {
  .info-container {
    margin-right: 15vw;
  }
}

@media (max-width: 900px) {
  .name {
    text-align: center;
    font-size: 27px;
  }
  
  .page-container {
    flex-direction: column;
  }

  .info-container {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 20vw;
  }

}

@media (max-width: 600px) {
  .info-container {
    margin-top: 30vw;
  }
}

@media (max-width: 430px) {
  .info-container {
    margin-top: 40vw;
  }
  
  .name {
    font-family: 'IBM Plex Sans', sans-serif;
  }

  h2 {
    font-family: 'IBM Plex Sans', sans-serif;
  }
}

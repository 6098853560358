.arrow {
  color: rgb(55,55,64);
  transition: transform 0.3s;
  font-size: 15px;
}

.link:hover .arrow {
  transform: translateX(5px);
  color: #0072b1;
}

.info-container .link {
  color: rgb(55,55,64);
}

.about-container .arrow {
  color: rgb(55,55,64);
  transition: transform 0.3s;
  font-size: 12px;
}

.link:hover .arrow {
  transform: translateX(5px);
  color: #0072b1;
}
